import File from "@/scripts/models/file.model";
import Address from "@/scripts/models/address.model";
import {    
    VolunteeringTypes,
} from "@/scripts/models/enums/volunteeringType.enum.js";

export default class VolunteeringAttributeOffer {

    constructor() {
        this.id = -1;
        this.volunteeringId = -1;
        this.offerName = '';
        this.function = '';
        this.preRequisites = '';
        this.isDistanceOportunityAllowed = true; 
        this.isPresentialOportunityAllowed = true;         
        this.totalOfferAmount = 1;

        this.volunteeringType = VolunteeringTypes[0];
        
        this.file = new File();
        this.address = new Address();
        
        this.volunteeringRecurrentTypes = [];
        this.volunteeringPontualTypes = [];
    }
}