import File from "@/scripts/models/file.model";
import Project from "@/scripts/models/project.model";
export default class Volunteering {

    constructor() {
        this.id = 0;
        this.fullName = '';
        this.shortName = '';
        this.tags = '';
        this.causeId = null;
        this.targetAudienceId = null;
        this.volunteeringTypeId = 0;     
        this.beneficiaries = 0;
        this.about = '';
        this.activities = '';
        this.initialFinalDates = [];
        this.initialDate = '';
        this.finalDate = '';
        this.institutionId = null;
        this.project = new Project();
        this.published = false;

        this.ageRangeList = [];
        this.volunteeringCoverages = [];
        this.volunteeringVolunteeringTypes = [];
        this.scheduleList = [];
        this.goalList = [];
        this.newsList = [];
        this.followerList = [];

        this.documentsCheck = 0;
        this.newsCheck = 0;
        this.galleryCheck = 0;
        this.scheduleCheck = 0;

        this.stepsValidationStatus = {};
        
        this.faviconFile = new File();
        this.metaTitle = "";
        this.metaDescription = "";
    }
}