const VolunteeringTypeEnum = {
	Pontual: 1,
	Recorrente: 2
};

const VolunteeringTypes = [
	{id: 1, name: "Pontual"},
	{id: 2, name: "Recorrente"}	
]

export { VolunteeringTypeEnum, VolunteeringTypes };